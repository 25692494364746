<template>
        <div id="Hello-comparison-Bar" class="hello w-100 mb-2 rounded-2" ref="chartdiv"></div>
</template>

<script>
/* eslint-disable */
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import api from '@/services/api'
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default {
    props: {
        regions: {
            type: Array,
            required: true,
        },
        countriesData: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            data: [],
            selectedCountry: null,
            selectedInd: this.$route.params.indicator,
            indicatorData: {},
            indicatorName: null
        }
    },
    methods: {
        getColor(value) {
            const ranges = [1, 2.001, 2.995, 3.5, 4, 5];
            const colors = ['#029602', '#78be21', '#f1c232', '#eb4b52', '#cc0000'];

            let category = '';
            if (value >= 5) {
                category = colors[4];
            } else {
                for (let i = 1; i < ranges.length; i++) {
                    if (value >= ranges[i - 1] && value < ranges[i]) {
                        category = colors[i - 1];
                        break;
                    }
                }
            }
            return category
        },
        async getIndicator() {
          try {
            const reponse = await api.get(`data/ssind/${this.selectedInd}?start_date=2024-01-01&end_date=2024-12-31`)
            this.indicatorData = await reponse.data

            this.indicatorData.data.forEach(indicator => {
              this.data.forEach(country => {
                if (indicator.country_name == country.country) {
                  country.value = Math.round(indicator.myvalue * 10) / 10
                }
              })
            })
          } catch (error) {
            console.error('Erreur lors de la récupération des données : ', error)
          }
        },
        selectedIndicator()  {
            if (this.$route.params.indicator) {
                const indicator = this.$route.params.indicator.split('?')
                this.selectedInd = indicator[0];
            }
        },
        parseRegion() {
            this.selectedCountry = this.$route.params.region;
            if (this.selectedCountry) {
                const indexVirgule = this.selectedCountry.indexOf(',');
                if (indexVirgule !== -1) {
                    this.selectedCountry = this.selectedCountry.split(',').map(country => country.trim());
                    console.log("Multiple countries:", this.selectedCountry);
                }
            }
            this.selectedIndicator()
            this.parseCountryInRegion()
        },
        parseCountryInRegion() {
            if (this.selectedCountry && this.selectedCountry.length > 0) {
                const countriesArray = this.countriesData.data;

                const countryNames = countriesArray.filter(country => {
                    return this.selectedCountry.includes(country.country_name);
                }).map(selected => ({
                    country: selected.country_name,
                    value: 4,
                }));

                this.data_country_final = countryNames;
            }
            if (this.selectedCountry) {
                console.log(this.selectedCountry)
                const regionsWithCountries = {};
                const countriesArray = this.countriesData.data;

                const selectedRegions = Array.isArray(this.selectedCountry)
                    ? this.selectedCountry
                    : [this.selectedCountry];

                selectedRegions.forEach(region => {
                    const countriesInRegion = countriesArray.filter(country => {
                        return country.region_name === region;
                    });
                    const countryNamesInRegion = countriesInRegion.map(country => ({
                        country: country.country_name,
                        value: 4,
                    }));
                    regionsWithCountries[region] = countryNamesInRegion;
                });
                const additionalData = selectedRegions.reduce((acc, region) => {
                    return acc.concat(regionsWithCountries[region] || []);
                }, []);
                this.data = this.data.concat(additionalData).concat(this.data_country_final);
                const uniqueCountries = Array.from(new Set(this.data.map(item => item.country)));
                this.data = uniqueCountries.map(country => ({
                    country,
                    value: 4,
                }));
            } else {
                this.data = [];
            }
        },
        async renderChart() {
            let root = am5.Root.new(this.$refs.chartdiv);
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout
            }));
            
            chart.zoomOutButton.set("forceHidden", true);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30
            });
            xRenderer.labels.template.setAll({
                rotation: 0,
                centerY: am5.p50,
                centerX: am5.p50,
                maxWidth: 150,
            });
            xRenderer.grid.template.set("visible", false);

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "country",
                renderer: xRenderer
            }));
            
            xAxis.onPrivate("cellWidth", function(cellWidth) {
                let rotated = false;
                xRenderer.labels.each(function(label) {
                if (label.width() > cellWidth) {
                    rotated = true;
                }
                });
    
                if (rotated) {
                    xRenderer.labels.template.setAll({
                    rotation: -45,
                    centerX: am5.p100
                    });
                }
                else {
                    xRenderer.labels.template.setAll({
                    rotation: 0,
                    centerX: am5.p50
                });
            }
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                min: 0,
                max: 5,
                calculateTotals: true,
                maxPrecision: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "country"
            }));

            series.columns.template.setAll({
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                strokeOpacity: 0
            });
            series.columns.template.adapters.add("fill", (fill, target) => {
                if (this.getColor(target.dataItem.get("valueY"))) {
                    return am5.color(this.getColor(target.dataItem.get("valueY")))
                } else {
                    return am5.color("#000")
                }
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target ));
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                locationY: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueYWorking.formatNumber('#.#')}",
                    fill: am5.color("#000"),
                    centerY: 0,
                    centerX: am5.p50,
                    populateText: true
                    })
                });
            });

            xAxis.data.setAll(this.data);
            series.data.setAll(this.data);

            const resp = await api.get(`/ssind/${this.selectedInd}`)
            const data = resp.data
            this.indicatorName = data.data[0].name

            chart.children.unshift(am5.Label.new(root, {
                text: this.indicatorName + "\n",
                fontSize: 35,
                fontWeight: "500",
                textAlign: "center",
                x: am5.percent(50),
                centerX: am5.percent(50),
                paddingTop: 0,
                paddingBottom: 0
            }));


        setInterval(function () {
            updateData();
        }, 1500)

        function updateData() {
            sortCategoryAxis();
        }
        function getSeriesItem(category) {
            for (var i = 0; i < series.dataItems.length; i++) {
                let dataItem = series.dataItems[i];
                if (dataItem.get("categoryX") == category) {
                    return dataItem;
                }
            }
        }
        function sortCategoryAxis() {
            series.dataItems.sort(function (x, y) {
                return y.get("valueY") - x.get("valueY");
            })
            am5.array.each(xAxis.dataItems, function (dataItem) {
                let seriesDataItem = getSeriesItem(dataItem.get("category"));
                if (seriesDataItem) {
                    let index = series.dataItems.indexOf(seriesDataItem);
                    let deltaPosition = (index - dataItem.get("index", 0)) / series.dataItems.length;
                    dataItem.set("index", index);
                    dataItem.set("deltaPosition", -deltaPosition);
                    dataItem.animate({
                        key: "deltaPosition",
                        to: 0,
                        duration: 1000,
                        easing: am5.ease.out(am5.ease.cubic)
                    })
                }
            });
            xAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }
        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            htmlOptions: {
                disabled: true
            },
            pdfdataOptions: {
                disabled: true
            },
            xlsxOptions: {
                disabled: true
            },
            csvOptions: {
                disabled: true
            },
            jsonOptions: {
                disabled: true
            },
            htmlOptions: {
                disabled: true
            },
            printOptions: {
                disabled: true
            },
            dataSource: this.data,
            filePrefix: "ChartRisk",
            align: "left",
            valign: "top"
        });

        series.appear(1000);
        chart.appear(1000, 100);
        }
    },
    async mounted() {
        this.parseRegion()
        await this.getIndicator()
        this.renderChart()
        console.log(this.selectedInd)

    }
}
</script>
