<template>
    <section id="methodo-page">

    <h1 class="text-danger fw-bold text-center px-5 mt-3">Veolia Country Risk Platform</h1>
    <p>
        Veolia Country Risk & Opportunity platform is a tool aiming at assessing the risk profile of the countries of interest for the group by scoring them according to Veolia industry’s challenges. The methodology has been regularly updated and improved since its development in 2012 to enhance the output and to highlight the primary risks faced by the company nowadays as well as an evaluation of market attractiveness for the group.
    </p>
    <h2 class="fw-bold px-5 mt-2">General Principles and Methodology</h2>
    <p>
        From a conceptual and academic perspective, the country risk is defined as an uncertainty on the “macro” drivers of a country (economic, financial, political, social...) and the impact these elements have on a project’s development or on an investment’s expected return. The country risk analysis shall be used to present insights and indicators that help understand if a country is more or less stable, based on past and present observations.
    </p>
    <h2 class="fw-bold px-5 mt-2">Data Sources & List of Countries</h2>
    <p>
        The Rating Assessments use a wide variety of sources. It is from these sources that the data used for the indicators, which constitute the rating’s backbone, have been gathered. The decision to use a wide variety of sources introduces a certain degree of independence and objectivity to the rating, as it does not rely on a single provider. These sources include various internationally recognized and reputable organizations, both public - such as the World Bank, the IMF, the UN, the WHO, the U.S. Energy Information Administration, the Bank for International Settlements, European Commission, universities and private institutions.
    </p>
    <h3 class="fw-bold px-5 mt-1">The regions and countries covered by this report include:</h3>
    <div class="w-100 d-flex justifyc-content-center justify-xxl-content-start flex-wrap col">
    <div class="col-12 col-xxl-6">
    <!-- <ul> -->
        <li><b>North America:</b> Canada, United States of America </li>
        <li><b>Western Europe:</b> Austria, Belgium, France, Germany, Ireland, Liechtenstein, Luxembourg, Netherlands, Switzerland, United Kingdom</li>
        <li><b>Northern Europe:</b> Denmark, Finland, Greenland, Iceland, Norway, Sweden</li>
        <li><b>Eastern Europe:</b> Albania, Armenia, Azerbaijan, Belarus, Bosnia-Herzegovina, Bulgaria, Croatia, Czech Rep., Estonia, Georgia, Hungary, Latvia, Lithuania, Macedonia, Moldova, Montenegro, Poland, Romania, Serbia, Slovakia, Slovenia, Ukraine</li>
        <li><b>Southern Europe:</b> Greece, Italy, Malta, Portugal, Spain</li>
        <li><b>Central Asia:</b> Afghanistan, Kazakhstan, Kyrgyzstan, Russia, Tajikistan, Turkmenistan, Uzbekistan</li>
        <li><b>South East Asia:</b> Brunei, Cambodia, Indonesia, Laos, Malaysia, Philippines, Singapore, Sri Lanka, Thailand, Vietnam</li>
        <li><b>Southern Asia:</b> Bangladesh, India, Myanmar, Nepal, Pakistan</li>
        <li><b>Eastern Asia:</b> China, Guam, Hong Kong, Japan, Macao, Mongolia, South Korea, Taiwan</li>
        <li><b>Oceania:</b> Australia, New Zealand, Papua New Guinea</li>
        <li><b>Central America:</b> Costa Rica, Cuba, Dominican Rep., El Salvador, Guatemala, Haiti, Honduras, Jamaica, Mexico, Nicaragua, Panama, Puerto Rico</li>
        <li><b>South America:</b> Argentina, Bolivia, Brazil, Chile, Colombia, Ecuador, Guyana, Paraguay, Peru, Suriname, Uruguay, Venezuela</li>
        <li><b>Near & Middle East:</b> Bahrain, Cyprus, Iran, Iraq, Israel, Jordan, Kuwait, Lebanon, Oman, Palestine, Qatar, Saudi Arabia, Syria, Turkey, U.A.E., Yemen</li>
        <li><b>Northern Africa:</b> Algeria, Egypt, Libya, Morocco, Tunisia</li>
        <li><b>Western Africa:</b> Benin, Burkina Faso, Cape Verde, Cote d’Ivoire, Gambia, Ghana, Guinea, Guinea Bissau, Liberia, Mali, Mauritania, Niger, Nigeria, Senegal, Sierra Leone, Togo</li>
        <li><b>Central Africa:</b> Burundi, Cameroon, Central African Rep, Chad, Congo, DR Congo, Equatorial Guinea, Gabon, Rwanda</li>
        <li><b>Eastern Africa:</b> Djibouti, Eritrea, Ethiopia, Kenya, Madagascar, Mauritius, Somalia, South Sudan, Sudan, Tanzania, Uganda</li>
        <li><b>Southern Africa:</b> Angola, Botswana, Lesotho, Mozambique, Namibia, South Africa, Zambia, Zimbabwe</li>
    <!-- </ul> -->
    </div>
    <div class="col-12 col-xxl-6 d-flex d-xxl-block justify-content-center mt-3 mt-xxl-0">
        <img id="img_region" src="@/assets/regionsv2.png">
    </div>
    </div>
    <h2 class="fw-bold px-5 mt-2">Country Risk - A Russian Doll Philosophy</h2>
    <p>
        The country risk assessment is built in a way that resembles the functioning of Russian dolls. The idea behind this Russian doll theory is to assemble the various results provided by the data at different hierarchical levels. Each time the reader reaches a lower level (opening a doll to access the smaller one within), he is presented with a more detailed and comprehensive set of data and results. This enables the reader to access the information he needs quickly, whether detailed or synthetic, whilst allowing for a certain degree of modularity in the use of the data. It can therefore easily be incorporated, whether at the general score level or the chapter lever (see below), into another tool or document.
    </p>
    <p>
        There are 5 levels of information, going from the highly synthetic, the country risk rating, to the highly detailed, the raw data, these indicators are statistically normalized so that they may be measured on a relative scale.
    </p>
    <!-- <ol> -->
        <li><b>Level one: <i>Country Risk assessment</i></b> . The risk assessment itself consists of a number (from 1 to 5). An <span style="color: #029602;" class="fw-bold">“Low Risk”</span> corresponds to a rating inferior or equal to 2. A <span class="fw-bold" style="color: #78be21;">“Moderately Low Risk”</span> is associated to a rating comprised between 2.0 and 3.0. A <span class="fw-bold" style="color: #f1c232;">“Moderately High Risk”</span> is associated to a rating comprised between 3.0 and 3.5. A <span style="color: #eb4b52;" class="fw-bold">“High Risk”</span> is associated to a rating comprised between 3.5 and 4.0. A  <span style="color: #cc0000;" class="fw-bold">“Very High Risk”</span> is associated to a rating comprised superior to 4.0.</li>
        <li><b>Level two: <i>The 4 risk pillars</i></b> Country risk has been divided into five risk pillars: Economic & Business Country Risk, Political & Geopolitical Country Risk, Social & Societal Country Risk and Environmental Country Risk.</li>
        <li><b>Level three: <i>The 18 topics</i></b> Each pillar is structured by families which give the basic descriptive data on the risk pillar scoring. They enable the reader to understand which specific risk, or lack of risk, is responsible for the risk pillar score. </li>
        <li><b>Level four/five: <i>The 65 sub-topics</i></b> Each family is structured by indicators which give 
            the basic descriptive data on the risk family scoring. They enable the reader to understand 
            which specific risk, or lack of risk, is responsible for the risk family score.</li>
        <li><b>Level six: <i>The 157 indicators</i></b> These indicators are the backbone of the 
            risk rating methodology. They provide the data necessary to calculate the final scores. From 
            these indicators, which are grouped into indicators, families and pillars, we calculate the 
            scores for the various levels of the report.</li>
    <!-- </ol> -->
	<pre>	</pre>
	<p>
	Below decomposition of the 4 pillars.
	</p>
	<div class="logo-center d-flex justify-content-center gap-5 col mb-5 flex-wrap">
        <img src="@/assets/pilier1.png" class="col-6 img_pilier">
		<img src="@/assets/pilier2.png" class="col-6 img_pilier">
    </div>
	<div class="logo-center d-flex justify-content-center gap-5 col flex-wrap">
        <img src="@/assets/pilier3.png" class="col-6 img_pilier">
		<img src="@/assets/pilier4.png" class="col-6 img_pilier">
    </div>

    <h2 class="fw-bold px-5 mt-2">Normalization of indicators</h2>
    <p>
        The standardization of indicators is essential in order to put them all on a common scale and in a same direction in terms of their relationship to the degree of risk.
    </p>
    <p>
        The normalization used is based on a min-max methodology. The process starts with the identification of historical minimum and maximum (all countries, all years) for each indicator, and removing obvious outliers. When historical minimum and maximum are not in line with the conceptual vision of the indicator, a manual adjustment is made. Then indicators are scaled from 1 (corresponding to minimum value and risk) to 5 (maximum value and risk). This method has the advantage of preserving the order and distribution of the data.
    </p>
    <h2 class="fw-bold px-5 mt-2">Weights of indicators</h2>
    <p>
        The same weight is given to each of the selected indicators in each Sub-Topic. Similarly, the same weight is given to each Sub-Topic in each Topic, to each Topic in each Pillar and to each Pillar in the Overall Risk assessment.
    </p>
    <p>
        However, a selection of critical variables has been made to give them higher weight. If the value of a critical variable for a country is higher than the average, this value will be assigned as a priority to the Sub-Topic (if it is higher than the other values of the Sub-Topic), in order to take into account the high risk on indicators considered as critical.
    </p>
    <h3 class="fw-bold px-5 mt-1">List of critical indicators:</h3>
	<div class="logo-center d-flex justify-content-center">
        <img src="@/assets/tab_critind.png">
    </div>
    <p>
        <br>In addition, within the Economic and Business pillar, a very high risk level in key sub-topics such as Domestic economic tensions and Banking and financial system exerts a negative impact on the score of the pillar.
        <br>Similarly, within the Political and Geopolitical pillar, as well as the Overall risk score, the enforcement of significant sanctions by entities such as the EU, US, UN, or ECOWAS penalizes both the Political & Geopolitical risk score and the Overall risk score.
        <br>Finally, in the Political and Geopolitical Pillar, the sub-topic Critical Locks & Knots is included in the calculation of the  Geopolitcal Risk topic only if it worsens the risk. Similarly, the sub-topic Anti-Western Polarization is included in the calculation of the Alliance sub-topic if the indicator has a level higher than 2/5.
    </p>
	<h2 class="fw-bold px-5 mt-2">Reputation risk</h2>
	<p>
	Reputation risk refers to negative events or signals in the country which could impact the public perception and image of the company.
	</p>
	<p>
	A list of indicators has been identified as potentially increasing the risk of a bad reputation for Veolia
	</p>
	<div class="logo-center d-flex justify-content-center">
        <img src="@/assets/tab_repind.png">
    </div>
	<p>
		<br>Reputation risk index is an average of this list of normalized indicators.
	</p>
	<p>
        However, a list of 5 critical indicators is defined: Corruption Perception Index, Trade Union and labour rights, Modern slavery and Climate commitments. If one of this indicator is above 4 (on the scale 1 to 5), the weight of this indicator will be higher. Moreover, Sanctions indicators are also overweighted if the country have a bad score for these indicators.
	</p>
    </section>
</template>
