<template>
  <div class="w-100" id="table">
  <div class="title-container d-flex align-items-center fs-0 mb-0 rounded-0 text-white">
    <h3 class="title fs-4 m-0 ps-3 ps-md-5 py-2">Indicator</h3>
    <ul class="title-indicator d-flex fs-5 py-1 justify-content-around aligns-items-center m-0">
      <li>{{ selectedC }}<br> Reputation Risk Score</li>
      <li>{{ region }}<br>Average Risk Score</li>
      <li>World<br>Average Risk Score</li>
    </ul>
  </div>
  <div class="menu ">
    <ul class="first-label text-white mb-3">
      <li class="first-li" v-for="(lvl1item, lvl1index) in menuItems" :key="lvl1index">
      <div v-if="lvl1item.id.split('?')[0] !== 'overallcountryrisk'" class="label-container d-flex">
        <label class="first" :for="lvl1item.id"><div class="align-span d-flex align-items-center"><span>{{ lvl1item.label }}</span><span v-if="!lvl1item.id.includes('reputation_risk')" class="material-icons">arrow_drop_down</span></div></label>
        <ul class="data-indicator">
          <li class="border border-0 m-0 p-0" :style="{ backgroundColor: getColor(lvl1item.myvalue) }">{{ lvl1item.myvalue }}</li>
          <li class="border border-0 m-0 p-0" :style="{ backgroundColor: getColor(lvl1item.Rvalue) }">{{ lvl1item.Rvalue }}</li>
          <li class="border border-0 m-0 p-0" :style="{ backgroundColor: getColor(lvl1item.Wvalue) }">{{ lvl1item.Wvalue }}</li>

        </ul>
      </div>
        <input class="d-none" type="checkbox" :id="lvl1item.id">
        <ul class="lvl2menu pb-0 mb-0  pe-0" v-if="lvl1item.submenu && lvl1item.submenu.length > 0">
          <li v-for="(lvl2item, lvl2index) in lvl1item.submenu" :key="lvl2index">
          <div class="label-container d-flex"><label class="second" :for="lvl2item.id">
              <div class="align-span d-flex align-items-center">
                <!-- <span :class="{ 'bold-red': ['transpint_corruperc', 'ituc_globalrights','walkfree_modernslavery','wb_childlabour','climatewatch_commit'].some(substring => lvl2item.id.split('?')[0].includes(substring)) }">
                  {{ lvl2item.label + ('bold-red' in lvl2item ? '*' : '') }}
                </span> -->
                <span >
                  {{ lvl2item.label}}
                </span>
              </div>
            </label>
            <!-- <ul class="data-indicator-2" 
               :class="{ 'bold-red': ['transpint_corruperc', 'ituc_globalrights','walkfree_modernslavery','wb_childlabour','climatewatch_commit'].some(substring => lvl2item.id && lvl2item.id.split('?')[0].includes(substring)) }"> -->
              <ul class="data-indicator data-indicator-2">
                <li class="border border-0 ps-1" :style="{ backgroundColor: getColor(lvl2item.myvalue) }">{{ lvl2item.myvalue }}</li>
                <li class="border border-0 ps-1" :style="{ backgroundColor: getColor(lvl2item.Rvalue) }">{{ lvl2item.Rvalue }}</li>
                <li class="border border-0 ps-1" :style="{ backgroundColor: getColor(lvl2item.Wvalue) }">{{ lvl2item.Wvalue }}</li>
          </ul>
          </div>
 
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<div class="button-container d-flex justify-content-end mt-1">
    <button class="export-button text-white border border-0 py-2 px-4" @click="exportToCSV">Export table to CSV</button>
  </div>
</template>

<script>
/* eslint-disable */
import api from '@/services/api'
import html2pdf from 'html2pdf.js';

export default {
  props: {
    selectedC: {
      type: String,
      required: true,
    },
    data_indicator: {
      type: Array,
      required: true,
    },
    data_indicator_world: {
      type: Array,
      required: true,
    },
    data_indicator_region: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      inputStrings: [],
      menuItems: [],
      RenameItem: {specificNames: {}},
      csvPathFile: 'app.json',
      selectedC: '',
      country: {},
      region: '',
      regions_data: {},
      regionISO3: ''
    };
  },
  async mounted() {
    this.getRegions();
    await this.getCountries();
    this.getDataFromCsv();
    this.getDataIndicator();
    this.getDataIndicatorRegion();
    this.getDataIndicatorWorld();
    this.getSsindLab();
    console.log(this.menuItems)
  },
  methods: {
    getColor(value) {
      const ranges = [1, 2.001, 2.995, 3.5, 4, 5];
      const colors = ['#029602', '#78be21ff', '#f1c232', '#eb4b52', '#cc0000'];

      let category = '';
      if (value >= 5) {
        category = colors[4];
      } else {
        category = '';
        for (let i = 1; i < ranges.length; i++) {
          if (value >= ranges[i - 1] && value < ranges[i]) {
            category = colors[i - 1];
            break;
          }
        }
      }
      return category;
    },
    exportToCSV() {
      const data = this.menuItems;

      const keys = ['label', 'myvalue', 'Rvalue', 'Wvalue'];
      const headers = ['Label', 'Value', 'RegionValue', 'WorldValue'];
      if (typeof data === 'object' && data !== null) {
        let csvData = '';

        const flattenObject = (obj) => {
          let result = '';
          for (const key of keys) {
            if (obj.hasOwnProperty(key)) {  
              if(obj[key] !== undefined && obj[key] !== null && obj[key] !== 0){
                result += obj[key];
              }
            }
            result += ',';
          }
          return result.slice(0, -1); // remove trailing comma
        };

        let specialLine = '';
        const processLevel = (levelData) => {
          for (const key in levelData) {
            if (levelData.hasOwnProperty(key)) {
              const line = flattenObject(levelData[key]) + '\n';
              if (levelData[key].id.startsWith("reputation_risk")) {
                specialLine = line;
              } else {
                csvData += line;
              }
              if (levelData[key].submenu) {
                processLevel(levelData[key].submenu);
              }
            }
          }
        };

        processLevel(data);

        csvData = headers.join(',') + '\n' + specialLine + csvData;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ReputationRisk_' + this.selectedC + '.csv';
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Data is not an object or is null.');
      }
    },
    exportToPDF() {
        const element = document.getElementById('table');
        html2pdf()
          .from(element)
          .save('IndicatorTable.pdf');
      },
      exportToJSON() {
      const jsonData = JSON.stringify(this.RenameItem);
      const blob = new Blob([jsonData], { type: 'application/json' });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'IndiactorData.json';
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    },
    async getCountries() {
        try {
            const response = await api.get('/countries');
            const data = response.data;
            data.data.forEach(obj => {
              this.country[obj.country_name] = {
                region: obj.region_name
              }
            })
            const countryRegion = this.$route.params.country;
            for (const countryName in this.country) {
              if (countryName === countryRegion) {
                const countryData = this.country[countryName];
                this.region = countryData.region;
                break;
              }
            }
            for (const regionName in this.regions_data) {
              const region = this.regions_data[regionName];
              if (regionName == this.region)
                this.regionISO3 = region.region
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des pays :', error);
        }
    },
    async getRegions() {
      try {
        const response = await api.get('/regions');
        const data = response.data;
        data.data.forEach(obj => {
              this.regions_data[obj.name] = {
                region: obj.id
              }
          })
      } catch (error) {
        console.error('Erreur lors de la récupération des regions_data :', error);
      }
    },
    getDataIndicator() {
        this.selectedC = this.$route.params.country;
        this.data_indicator.forEach(indicator => {
          for (const key in this.RenameItem) {
            if (this.RenameItem.hasOwnProperty(key))
              if (key == indicator.symbol) {
                this.RenameItem[key].myvalue = Math.round(indicator.myvalue * 100) / 100;
                this.RenameItem[key].rank = indicator.rank;
              }
          }
          for (const key in this.RenameItem.specificNames) {
            if (this.RenameItem.specificNames.hasOwnProperty(key)) {
              const lastSlashIndex = key.lastIndexOf('/');
              if (lastSlashIndex !== -1) {
                const newKey = key.substring(lastSlashIndex + 1);
                if (newKey === indicator.symbol) {
                  this.RenameItem.specificNames[key].myvalue = Math.round(indicator.myvalue * 100) / 100;
                  this.RenameItem.specificNames[key].rank = indicator.rank;
                }
              }
            }
          }
        })
    },
    async getDataIndicatorRegion() {
        this.data_indicator_region.forEach(indicator => { 
            for (const key in this.RenameItem) {
                if (this.RenameItem.hasOwnProperty(key))
                  if (key == indicator.symbol) {
                    this.RenameItem[key].Rvalue = Math.round(indicator.value * 100) / 100;
                  }
            }
            for (const key in this.RenameItem.specificNames) {
              if (this.RenameItem.specificNames.hasOwnProperty(key)) {
                const lastSlashIndex = key.lastIndexOf('/');
                if (lastSlashIndex !== -1) {
                  const newKey = key.substring(lastSlashIndex + 1);
                  if (newKey === indicator.symbol) {
                    this.RenameItem.specificNames[key].Rvalue = Math.round(indicator.value * 100) / 100;
                  }
                }
              }
            }
          })
    },
    getDataIndicatorWorld() {
      this.data_indicator_world.forEach(indicator => {
            for (const key in this.RenameItem) {
                if (this.RenameItem.hasOwnProperty(key))
                  if (key == indicator.symbol) {
                    this.RenameItem[key].Wvalue = Math.round(indicator.value * 100) / 100;
                  }
            }
            for (const key in this.RenameItem.specificNames) {
              if (this.RenameItem.specificNames.hasOwnProperty(key)) {
                const lastSlashIndex = key.lastIndexOf('/');
                if (lastSlashIndex !== -1) {
                  const newKey = key.substring(lastSlashIndex + 1);
                  if (newKey === indicator.symbol) {
                    this.RenameItem.specificNames[key].Wvalue = Math.round(indicator.value * 100) / 100;
                  }
                }
              }
            }
          })
    },
    buildRenameItem(obj, path) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const item = obj[key];
          const newPath = path ? `${path}/${key}` : key;
          if (item && item.code && item.code.indexOf('/') === -1) {
            this.RenameItem[item.code] = { label: item.description, Rvalue : item.Rvalue };
          } else {
            this.RenameItem.specificNames[item.code] = { label: item.description, Rvalue : item.Rvalue };
          }
          if (typeof item === 'object' && !Array.isArray(item)) {
            this.buildRenameItem(item, newPath);
          }
        }
      }
    },
    getDataFromCsv() {
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          const item = this.data[key];
          this.inputStrings.push(item.code);
        }
      }
      this.buildRenameItem(this.data, '');
    },
    async getSsindLab() {
      try {
          const response = await api.get('/ssind');
          const data = response.data;
          this.inputStrings.forEach(inputString => {
              this.generateMenu(inputString);
          });

          this.RenameInput(this.menuItems);

      } catch (error) {
          console.error('Erreur lors de la récupération des Indicateurs :', error);
      }
    },
    generateMenu(inputString) {
      const levels = inputString.split('/');
      let currentLevel = this.menuItems;

      levels.forEach((level) => {
        let existingItem = currentLevel.find(item => item.label === level);

        if (!existingItem) {
          const menuItem = {
            id: `${level}?${Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)}`,
            label: level,
            submenu: [],
            value: null,
            region: 0,
            world: 0,
          };
          currentLevel.push(menuItem);
          currentLevel = menuItem.submenu;
        } else {
          currentLevel = existingItem.submenu;
        }
      });
    },
    RenameInput(tab) {
      this.recursiveRename(tab);
  },
  recursiveRename(submenu, parentLabel = null) {
    submenu.forEach((item) => {
      const itemLabel = item.label;
      const combinedLabel = parentLabel ? parentLabel + "/" + itemLabel : itemLabel;

      if (this.RenameItem.specificNames[combinedLabel] && this.RenameItem.specificNames[combinedLabel].label) {
        item.label = this.RenameItem.specificNames[combinedLabel].label;
        item.myvalue = this.RenameItem.specificNames[combinedLabel].myvalue;
        item.Rvalue = this.RenameItem.specificNames[combinedLabel].Rvalue;
        item.Wvalue = this.RenameItem.specificNames[combinedLabel].Wvalue;
        if (item.myvalue == 0) {
          item.myvalue = null
        }
        if (item.Rvalue == 0) {
          item.Rvalue = null
        }
        if (item.Wvalue == 0) {
          item.Wvalue = null
        }
      } else if (parentLabel && this.RenameItem.specificNames[parentLabel] && this.RenameItem.specificNames[parentLabel].label) {
        item.label = this.RenameItem.specificNames[parentLabel].label;
        item.myvalue = this.RenameItem.specificNames[parentLabel].myvalue;
        item.Rvalue = this.RenameItem.specificNames[parentLabel].Rvalue;
        item.Wvalue = this.RenameItem.specificNames[parentLabel].Wvalue;
        if (item.Rvalue == 0) {
          item.Rvalue = null
        }
        if (item.myvalue == 0) {
          item.myvalue = null
        }
        if (item.Wvalue == 0) {
          item.Wvalue = null
        }
      } else if (this.RenameItem[itemLabel] && this.RenameItem[itemLabel].label) {
        item.label = this.RenameItem[itemLabel].label;
        item.myvalue = this.RenameItem[itemLabel].myvalue;
        item.Rvalue = this.RenameItem[itemLabel].Rvalue;
        item.Wvalue = this.RenameItem[itemLabel].Wvalue;
        if (item.myvalue == 0) {
          item.myvalue = null
        }
        if (item.Rvalue == 0) {
          item.Rvalue = null
        }
        if (item.Wvalue == 0) {
          item.Wvalue = null
        }
      }

      if (item.submenu && item.submenu.length > 0) {
        this.recursiveRename(item.submenu, combinedLabel);
      }
    });
  },
  },
};
</script>